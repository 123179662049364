/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./UserCard.scss";
/* Common */
import * as Constants from '../../common/Constants';
import MiniButton from "../Buttons/MiniButton/MiniButton";
/* Service */
import StringService from '../../services/StringService';
import ApiService from '../../services/ApiService';
import RepairmanFile from "../Buttons/RepairmanFile/RepairmanFile";
//Class
const stringService = new StringService();
const apiService = new ApiService();


class UserCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
         tabOpen           : false,

         isValid           : false,

         isDisabled        : false,
         isDisableLoading  : false,
         isSendLoading     : false,
         isValidLoading    : false,

         warnNumber        : 0,
         warnDate          : null,

         driverCardChecked : false,
         assuranceChecked  : false,
         vehicleRegChecked : false,
         kbisChecked       : false,
      }
   }
   componentDidMount(){
      let {user} = this.props;
      let{warnNumber, warnDate, isValid} = this.state;

      if(user['warned']){
         warnNumber = user['warned']['times'];
         warnDate   = user['warned']['lastWarnDate'];
      } 
      if(user['verified']) isValid = true;

      this.setState({
         isDisabled : user['disabled'] ? true : false,
         warnNumber : warnNumber,
         warnDate   : warnDate,
         isValid    : isValid,
      })
	}

   //------------------------------------------------------------//
	//------------------------- DATA -----------------------------//
   //------------------------------------------------------------//

   /* Open / close the tab */
   _openTab = () => {
      let {tabOpen} = this.state;
      this.setState({ tabOpen : !tabOpen })
   }
   
   /* Disable the user */
   _disableUser = async (user) => {
      let{ isDisabled } = this.state;

      this.setState({isDisableLoading:true})

      let hasToBeDisabled = true;
      if(isDisabled) hasToBeDisabled = false;

      let res = await apiService.restrictUser(user['phoneID'], hasToBeDisabled);
      if(res.success){
         stringService.toast(res.text);
         this.setState({ isDisabled : hasToBeDisabled })
      }
      this.setState({isDisableLoading:false});
   }

   /* Get the user profile pic */
   _getProfilePic(user){
      window.open(user['profilePicURL'], "_blank");
   }

   /* Send a mail to the user */
   _sendMail(user){
      window.location.href = "mailto:"+user['mail'];
   }

   /* Check the document */
   _checkFile(stateIndex, check){
      this.setState({
         [stateIndex] : check
      })
   }

   /* User can be warned */
   _canWarn(){
      let {driverCardChecked, assuranceChecked, vehicleRegChecked, kbisChecked} = this.state;
      let canWarn = false;
      if(driverCardChecked) canWarn = true;
      if(assuranceChecked)  canWarn = true;
      if(vehicleRegChecked) canWarn = true;
      if(kbisChecked)       canWarn = true;
      return canWarn;
   }

   /* Send a mail to tell user to chnage invalid documents  */
   async _sendDocumentsWarning(user){
      let {warnNumber, driverCardChecked, assuranceChecked, vehicleRegChecked, kbisChecked} = this.state;

      let canWarn = this._canWarn();

      if(canWarn){
         this.setState({isSendLoading:true});
   
         let res = await apiService.warnUser(warnNumber, user, driverCardChecked, assuranceChecked, vehicleRegChecked, kbisChecked);
         if(res.success){
            stringService.toast(res.text);
            this.setState({
               isSendLoading : false,
               warnNumber    : warnNumber+1,
               warnDate      : stringService.getCurrentFRDate(),
               isValid       : false
            });
         }
      }else{
         stringService.toast(Constants.NO_FILE_SELECTED);
      }
   }

   /* Valid the user docs */
   async _validUserDocs(user){
      this.setState({isValidLoading:true});

      let res = await apiService.validDocs(user);
      if(res.success){
         stringService.toast(res.text);
         this.setState({ 
            warnNumber : 0,
            isValid    : true
         })
      }
      this.setState({isValidLoading:false});
   }
   
	//------------------------------------------------------------//
	//------------------------ RENDER ----------------------------//
   //------------------------------------------------------------//

   /* Dispaly the warn banner */
   _displayWarnBanner(user, warnNumber, warnDate){
      return(
         <>
            <div className="warnBanner">
               <div className="warnBanner-body">
                  <div className="warnBanner-body__icon"><i className='bx bx-error bx-sm'></i></div>
                  <div>
                     <div className="warnBanner-body__text">
                        L'utilisateur a été averti <div className="warnBanner-body__text --accent">{warnNumber}</div> fois. 
                        Dernier avertissement le <div className="warnBanner-body__text --accent">{warnDate}</div>
                     </div>
                     <div>
                        {this._displayValidButton(user, true)}
                     </div>
                  </div>
               </div>
            </div>
         </>
      )
   }

   /* User full name */
   _displayFullName(user){
      return user['name'].toUpperCase()+" "+stringService.uppercaseFirstLetter(user['surname']);
   }

   /* Display the button to disable user */
   _displayDisableButton(user){
      let {isDisabled, isDisableLoading} = this.state;
      return(
         <MiniButton 
            icon={
               isDisableLoading 
               ? <i className='bx bx-loader-circle bx-sm'></i> 
               : isDisabled 
                  ? <i className='bx bxs-lock-open bx-sm'></i> 
                  : <i className='bx bxs-lock bx-sm'></i>} 
            label={
               isDisableLoading ? "Chargement..." 
               : isDisabled ? "Débloquer l'utilisateur" : "Bloquer l'utilisateur"}
            color={
               isDisableLoading ? "disable" 
               : isDisabled ? "green" : "red"}
            onClick={() => {this._disableUser(user)}} 
            disable={this.state.isDisableLoading}
         />
      )
   }

   /* Display the button to valid user docs */
   _displayValidButton(user, warn){
      let {isValidLoading} = this.state;
      return(
         <MiniButton 
            icon={isValidLoading ? <i className='bx bx-loader-circle bx-sm'></i>
               : <i className='bx bx-check bx-sm'></i>} 
            label={isValidLoading ? "Chargement" : warn ? "Confirmer les documents" : "Conforme" }
            color={isValidLoading ? "disable" : warn ? "black-border" : "blue" }
            onClick={() => this._validUserDocs(user)} 
            disable={this.state.isValidLoading}
         />
      )
   }

   /* Display the button to warn user */
   _displayWarnButton(user){
      let {isSendLoading} = this.state;
      return(
         <MiniButton 
            icon={isSendLoading ? <i className='bx bx-loader-circle bx-sm'></i>
               : <i className='bx bx-x bx-sm'></i>} 
            label={isSendLoading ? "En cours d'envoi" : "Non conforme" }
            color={isSendLoading ? "disable" : "red-border"}
            onClick={() => this._sendDocumentsWarning(user)} 
            disable={this.state.isSendLoading}
         />
      )
   }

   /* Information item */
   _displayInfo(label, data, color){
      return (
         <div className="infoLine-info">
            <div className="infoLine-info__title">
               {label}
            </div>
            <div className="infoLine-info__data">
               {color &&(<div className="infoLine-info__data --color" style={{"background": "#"+color}}></div>)}
               {data}
            </div>
         </div>
      );
   }

   /* Repairman status */
   _displayRepairmanProfile(isPro){
      if(isPro) return 'Professionnel'
      else return 'Particulier'
   }

   /* Repairman : line item of file uploaded */
   _displayFileLine(title, fileCode, stateIndex){
      let {user} = this.props;
      return <RepairmanFile user={user} title={title} fileCode={fileCode} onClick={(check) => this._checkFile(stateIndex, check)}></RepairmanFile>
   }

	render(){
      let {tabOpen, isDisabled, warnNumber, warnDate, isValid} = this.state;
      let {user} = this.props;
		return(
         <>
            {user !== {}  &&(
               <div className="userCard">

                  {/* Header */}
                  <div className="userCard-header" onClick={this._openTab}>
                     <div className="userCard-header__lead">
                        {isDisabled   &&(<div title="Utilisateur bloqué"  className="userCard-header__lead__disabled"><i className='bx bxs-lock bx-sm'></i></div>)}
                        {warnNumber>0 &&(<div title="Utilisateur averti"  className="userCard-header__lead__warn"><i className='bx bxs-error bx-sm'></i></div>)}
                        {isValid      &&(<div title="Utilisateur vérifié" className="userCard-header__lead__valid"><i className='bx bxs-check-circle bx-sm'></i></div>)}
                        <div className="userCard-header__lead__name">{this._displayFullName(user)}</div>
                     </div>
                     {!tabOpen &&(<i className='bx bx-chevron-down bx-sm'></i>)}
                     {tabOpen  &&(<i className='bx bx-chevron-up bx-sm'></i>)}
                  </div>


                  {/* Body */}
                  {tabOpen &&(
                     <div className="userCard-body">

                        {/* Top button */}
                        <div className="userCard-body-buttons">
                           <div className="userCard-body-buttons --lead">
                              {this._displayDisableButton(user)}
                           </div>
                           <div className="userCard-body-buttons --end">
                              {user['profilePicURL'] &&(
                                 <MiniButton icon={<i className='bx bx-user bx-sm'></i>} label="Photo de profil" color="white" onClick={() => this._getProfilePic(user)} />
                              )}
                              {user['mail'] &&(
                                 <MiniButton icon={<i className='bx bx-mail-send bx-sm'></i>} label="Envoyer un mail" color="white" onClick={() => this._sendMail(user)} />
                              )}
                           </div>
                        </div>


                        {/* General infos */}
                        <div className="userCard-body-infos">
                           <div className="baseInfos">
                              <div className="infoLine">
                                 {this._displayInfo("ID Firebase",user['phoneID'])}
                              </div>
                              <div className="infoLine">
                                 {this._displayInfo("Nom",user['name'].toUpperCase())}
                                 {this._displayInfo("Prénom",stringService.uppercaseFirstLetter(user['surname']))}
                                 {this._displayInfo("Mail",user['mail'])}
                                 {this._displayInfo("Téléphone",user['phone'])}
                              </div>
                           </div>

                           {/* Repairman infos */}
                           {user['repairman'] &&(
                              <div className="repairmanInfos">

                                 {warnNumber>0 &&(this._displayWarnBanner(user, warnNumber, warnDate))}

                                 <div className="infoLine">
                                    {user['repairman'] && user['repairman']['isPro'] &&(
                                       <div className="infoLine-starIcon"><i className='bx bxs-star'></i></div>
                                    )}
                                    {this._displayInfo(this._displayRepairmanProfile(user['repairman']['isPro']), "")}
                                 </div>
                                 <div className="divider"></div>
                                 <div className="infoLine">
                                    {this._displayInfo("Véhicule", user['repairman']['vehicleType'], user['repairman']['vehicleColor']['hexCode'])}
                                    {this._displayInfo("Immatriculation",user['repairman']['immatriculation'].toUpperCase())}
                                 </div>
                                 {user['repairman'] && user['repairman']['society'] && user['repairman']['status'] &&(
                                    <>
                                       <div className="divider"></div>
                                       <div className="infoLine">
                                          {this._displayInfo("Statut",user['repairman']['status'])}
                                          {this._displayInfo("Société",user['repairman']['society'])}
                                       </div>
                                    </>
                                 )}
                                 {user['repairman'] && user['repairman']['files'] &&(
                                    <>
                                       <div className="divider"></div>
                                       <div className="infoLine">
                                          {this._displayInfo("Documents","")}
                                       </div>
                                       <div className="documents">
                                          <div className="documents-text">
                                             Cochez les cases des documents non conformes, et cliquez sur le bouton "Non conforme".
                                          </div>
                                          <div className="documents-items">
                                             {user['repairman']['files'][Constants.DRIVER_LICENCE] && (this._displayFileLine("Permis de conduire",Constants.DRIVER_LICENCE, "driverCardChecked"))}
                                             {user['repairman']['files'][Constants.ASSURANCE]      && (this._displayFileLine("Assurance",Constants.ASSURANCE, "assuranceChecked"))}
                                             {user['repairman']['files'][Constants.VEHICLE_REG]    && (this._displayFileLine("Carte Grise",Constants.VEHICLE_REG, "vehicleRegChecked"))}
                                             {user['repairman']['files'][Constants.KBIS]           && (this._displayFileLine("KBIS",Constants.KBIS, "kbisChecked"))}
                                          </div>
                                          <div className="documents-buttons">
                                             {warnNumber===0 && !isValid && !this._canWarn() &&(this._displayValidButton(user, false))}
                                             {this._canWarn() &&(this._displayWarnButton(user))}
                                          </div>
                                       </div>
                                    </>
                                 )}
                              </div>
                           )}
                        </div>
                     </div>
                  )}
               </div>
            )}
         </>
		);
	}
}

export default UserCard;

